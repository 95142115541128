import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiservicesService } from '../services/apiservices.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css'],
})
export class TrainingComponent implements OnInit {
  constructor(
    public router: Router,
    private authService: AuthService,
    public cookieService: CookieService,
    public activatedRoute: ActivatedRoute,
    private apiService: ApiservicesService,
    public matSnackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    console.log(this.activatedRoute.snapshot.url, 'AR');
    this.activatedRoute.url.subscribe((segments) => {
      const currentPath = segments.map((segment) => segment.path).join('/');
      this.hasTrainingsVideo = currentPath.includes('trainings-video');
    });
    console.log(this.hasTrainingsVideo, 'hasTrainingsVideo');
  }

  public cookieData: any = this.cookieService.getAll()['login_user_details']
    ? JSON.parse(this.cookieService.getAll()['login_user_details'])
    : {};

  public trainingData: any = [];

  public requiredVideos: any = [];
  public optionalVideos: any = [];

  public cliclspeedVideos: any = [];
  public othercliclspeedVideos: any = [];

  public trainingPercent: any = {};

  public loader: boolean = false;

  public hideContent: boolean = true;

  activeIndex: string | null = null;

  public intervalId: any;
  hasTrainingsVideo: any;

  ngOnInit() {
    console.log(this.activatedRoute.snapshot.url, 'AR');
    this.activatedRoute.url.subscribe((segments) => {
      const currentPath = segments.map((segment) => segment.path).join('/');
      this.hasTrainingsVideo = currentPath.includes('training-video');
    });
    console.log(
      this.hasTrainingsVideo,
      'hasTrainingsVideo after',
      this.cookieData.userinfo.RequiredPercentage,
      this.cookieData.userinfo.percentage
    );

    if (
      this.cookieData.userinfo.RequiredPercentage === 100 ||
      this.cookieData.userinfo.percentage === 100
    ) {
      if (!this.hasTrainingsVideo) {
        console.log('in backend');
        this.openModal();
      } else {
        // this.fetchTrainingPercent();
        this.hideContent = false;
        this.fetchTrainingPercent();
        this.activatedRoute.data.subscribe((response: any) => {
          if (response.data.status == 'success') {
            this.trainingData = response.data.results.res;
            console.log(this.trainingData, '========fdgsgs======>');

            this.requiredVideos = [];
            this.optionalVideos = [];
            this.trainingData.forEach((category: any) => {
              const required = category.category_data.filter(
                (data: any) => data.is_required == 1
              );
              const optional = category.category_data.filter(
                (data: any) => data.is_required == 0
              );
              if (required.length > 0) {
                this.requiredVideos.push({
                  category_name: category.category_name,
                  category_data: required,
                });
                console.log('requiredVideos', this.requiredVideos);
              }
              if (optional.length > 0) {
                this.optionalVideos.push({
                  category_name: category.category_name,
                  category_data: optional,
                });
              }

              console.log(this.requiredVideos, this.optionalVideos);
            });

            let onProgressFound = this.autoOpenOnProgressVideo(
              this.requiredVideos,
              true
            );

            if (!onProgressFound) {
              this.autoOpenOnProgressVideo(this.optionalVideos, false);
            }
          }
        });
      }
    } else {
      // if(!this.hasTrainingsVideo)
      // return;
      console.log('in else block');
      this.hideContent = false;
      this.fetchTrainingPercent();
      this.activatedRoute.data.subscribe((response: any) => {
        if (response.data.status == 'success') {
          this.trainingData = response.data.results.res;
          console.log(this.trainingData, '========fdgsgs======>');

          this.requiredVideos = [];
          this.optionalVideos = [];
          this.trainingData.forEach((category: any) => {
            const required = category.category_data.filter(
              (data: any) => data.is_required == 1
            );
            const optional = category.category_data.filter(
              (data: any) => data.is_required == 0
            );
            if (required.length > 0) {
              this.requiredVideos.push({
                category_name: category.category_name,
                category_data: required,
              });
            }
            if (optional.length > 0) {
              this.optionalVideos.push({
                category_name: category.category_name,
                category_data: optional,
              });
            }

            console.log(this.requiredVideos, this.optionalVideos);
          });

          let onProgressFound = this.autoOpenOnProgressVideo(
            this.requiredVideos,
            true
          );

          if (!onProgressFound) {
            this.autoOpenOnProgressVideo(this.optionalVideos, false);
          }
        }
      });
    }

    if (this.requiredVideos && this.requiredVideos.length > 0) {
      this.cliclspeedVideos = this.requiredVideos.filter(
        (video: any) => video.category_name === 'Clinical Speed Training'
      );
      this.othercliclspeedVideos = this.requiredVideos.filter(
        (video: any) => video.category_name !== 'Clinical Speed Training'
      );
    }
  }

  videoToggle(id: string, data: any) {
    if (data.status !== 'onprogress' && data.status !== 'is_completed') {
      this.matSnackBar.open(
        'Please complete your ongoing training before proceeding to the next step !!',
        '',
        {
          duration: 5000,
        }
      );
    }
  
    this.activeIndex = this.activeIndex == id ? null : id;
    console.log(id, this.activeIndex);
  
    if (this.activeIndex !== null) {
      this.scrollToBlock(id, data.is_required);
    }
  }
  

  autoOpenOnProgressVideo(videos: any[], isRequired: boolean): boolean {

    for (let i = 0; i < videos.length; i++) {
      const category = videos[i];
      for (let j = 0; j < category.category_data.length; j++) {

        const video = category.category_data[j];

        if (video.status === 'onprogress') {
          this.activeIndex = video._id;
          this.scrollToBlock(this.activeIndex, isRequired ? 1 : 0);
          return true;
        }

      }
    }
    return false;
  }

  scrollToBlock(id: any, isRequired: number) {
  
    setTimeout(() => {
      const element = document.getElementById(id);
  
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offset = 10;
  
        window.scrollTo({
          top: elementPosition - offset,
          behavior: 'smooth',
        });
      }
    }, 50);
  }
  

  onVideoEnd(data: any) {
    console.log('Video has ended:', data);
    this.loader = true;

    let requestBody = {
      video_id: data,
      rep_id: this.cookieData.userinfo._id,
    };

    this.apiService
      .getHttpDataPost('training/watch-done', requestBody)
      .subscribe({
        next: (response: any) => {
          if (response.status == 'success') {
            this.matSnackBar.open('Training done Successfully', 'Ok', {
              duration: 3000,
            });
            const requiredTrainings = this.trainingData.reduce(
              (acc: any[], category: any) => {
                return acc.concat(
                  category.category_data.filter(
                    (item: any) => item.is_required == 1
                  )
                );
              },
              []
            );

            const isLastRequiredVideo =
              requiredTrainings.length > 0 &&
              requiredTrainings[requiredTrainings.length - 1]._id == data;
            console.log(isLastRequiredVideo);

            if (isLastRequiredVideo) {
              console.log(isLastRequiredVideo);
              this.cookieData.userinfo.RequiredPercentage = 100;
              this.cookieService.set(
                'login_user_details',
                JSON.stringify(this.cookieData)
              );

              this.loader = false;
              // this.openModal();//
              console.log(
                this.hasTrainingsVideo,
                'another this.hasTrainingsVideo'
              );
              if (!this.hasTrainingsVideo) {
                console.log('in backend');
                this.openModal();
              }
            } else {
              this.fetchTrainingPercent();
              this.fetchTrainingList();
              this.loader = false;
            }
          } else {
            this.loader = false;
            this.matSnackBar.open('Something went Wrong !!', '', {
              duration: 3000,
            });
          }
        },
        error: (error: any) => {
          this.loader = false;
          this.matSnackBar.open('Something went Wrong !!', '', {
            duration: 3000,
          });
        },
      });
  }

  fetchTrainingPercent() {
    this.loader = true;
    let requestBody = {
      rep_id: this.cookieData.userinfo._id,
    };
    this.apiService
      .getHttpDataPost('training/check-percentage', requestBody)
      .subscribe({
        next: (response: any) => {
          console.log(response);

          if (response.status == 'success') {
            this.trainingPercent = response.results;
            if(this.trainingPercent?.lesson_not_done<0) 
              this.trainingPercent.lesson_not_done=0

            if(this.trainingPercent?.require_percentage>100) 
              this.trainingPercent.require_percentage=100

            if(this.trainingPercent?.percentage>100) 
              this.trainingPercent.percentage=100
            this.loader = false;

            if (response.results.percentage == 100 && !this.hasTrainingsVideo) {
              this.authService.updateTrainingPercentage(100);

              const dialogRef = this.dialog.open(trainingSuccessModal, {
                panelClass: ['custom-modalbox', 'trainingsuccess-modalbox'],
                data: this.cookieData,
              });
            }
          }
        },
        error: (error: any) => {
          this.loader = false;
        },
      });
  }

  fetchTrainingList() {
    console.log('in fetchTrainingList --- ');
    console.log('this.cookieData --- ', this.cookieData);
    this.loader = true;
    let requestBody = {
      condition: {
        limit: 10,
        skip: 0,
      },
      searchcondition: {
        rep_id: this.cookieData.userinfo._id,
      },
      sort: {
        type: 'asc',
        field: 'priority',
      },
      project: {},
      token: '',
    };

    this.apiService
      .getHttpDataPost('training/training-videos-list', requestBody)
      .subscribe({
        next: (response: any) => {
          if (response.status == 'success') {
            this.trainingData = response.results.res;
            this.loader = false;
            console.log(this.trainingData, '==============>');

            this.requiredVideos = [];
            this.optionalVideos = [];
            this.trainingData.forEach((category: any) => {
              const required = category.category_data.filter(
                (data: any) => data.is_required == 1
              );
              const optional = category.category_data.filter(
                (data: any) => data.is_required == 0
              );
              if (required.length > 0) {
                this.requiredVideos.push({
                  category_name: category.category_name,
                  category_data: required,
                });
              }
              if (optional.length > 0) {
                this.optionalVideos.push({
                  category_name: category.category_name,
                  category_data: optional,
                });
              }
              console.log(this.requiredVideos, this.optionalVideos);
            });

            if (this.requiredVideos && this.requiredVideos.length > 0) {
              this.cliclspeedVideos = this.requiredVideos.filter(
                (video: any) =>
                  video.category_name === 'Clinical Speed Training'
              );
              this.othercliclspeedVideos = this.requiredVideos.filter(
                (video: any) =>
                  video.category_name !== 'Clinical Speed Training'
              );
            }

            let foundOnProgress = false;

            this.requiredVideos.forEach((category: any, i: number) => {
              category.category_data.forEach((data: any, j: number) => {
                if (data.status === 'onprogress' && !foundOnProgress) {
                  this.activeIndex = data._id;

                  console.log(`Found in required: ${this.activeIndex}`);
                  foundOnProgress = true;
                }
              });
            });

            if (!foundOnProgress) {
              this.optionalVideos.forEach((category: any, i: number) => {
                category.category_data.forEach((data: any, j: number) => {
                  if (data.status === 'onprogress' && !foundOnProgress) {
                    this.activeIndex = data._id;
                    console.log(`Found in optional: ${this.activeIndex}`);
                    foundOnProgress = true;
                  }
                });
              });
            }

            if (this.activeIndex) {
              this.scrollToBlock(
                this.activeIndex as string,
                foundOnProgress ? 1 : 0
              )
              
            }
          } else {
            this.loader = false;
            this.matSnackBar.open('Something went Wrong !!', '', {
              duration: 3000,
            });
          }
        },
        error: (error: any) => {
          this.loader = false;
          this.matSnackBar.open('Something went Wrong !!', '', {
            duration: 3000,
          });
        },
      });
  }

  openModal() {
    console.log('modal open');
    // return;
    const dialogRef = this.dialog.open(trainingOptionModal, {
      panelClass: ['custom-modalbox', 'trainingoption-modalbox'],
      data: this.cookieData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result, 'dsfdfdsfds');

      // if (result == 'gameplanbook') {
      //   this.hideContent = false;
      // } else {
      this.fetchTrainingPercent();
      this.fetchTrainingList();
      this.hideContent = false;
      // }
    });
  }

  // << --------------- Game Plan Book Function ---------------- >>

  checkFlagAndRedirect() {
    let reqBody = {
      email: this.cookieData.userinfo.email,
    };
    this.apiService
      .getHttpDataPostWithoutBaseUrl(
        'https://i526y91jwg.execute-api.us-east-2.amazonaws.com/dev/api/check-atten-email-availability',
        reqBody
      )
      .subscribe({
        next: (response: any) => {
          console.log(response);
          if (response.status == 'success' && response.emailExists) {
            clearInterval(this.intervalId);
            this.router.navigateByUrl('/rep-dashboard');
          }
        },
        error: (error: any) => {},
      });
  }

  // << ---------------- Complete All Trainings --------------- >>

  completeAllTrainings() {

    this.loader = true;

    let reqBody = {
      rep_id: this.cookieData.userinfo._id
    }

    this.apiService.getHttpDataPost('training/complete-all-training', reqBody).subscribe({
      next: (response: any) => {
        if (response.status === 'success') {
          this.matSnackBar.open('All trainings are completed successfully !!', 'Ok', {
            duration: 5000,
          });
          this.fetchTrainingPercent();
              this.fetchTrainingList();
              console.log("before checkFlagAndRedirect call ")
              this.checkFlagAndRedirect();
          
        } else {
          this.matSnackBar.open('Something Went Wrong !!', '', {
            duration: 5000,
          });
        }
        this.loader = false;
      },
      error: (error: any) => {
        this.matSnackBar.open('Something Went Wrong !!', '', {
          duration: 5000,
        });
        this.loader = false;
      },
    });
  }
}

// << --------------- Required Training Success Modal Component ----------------- >>
@Component({
  selector: 'training-option-modal',
  templateUrl: 'training-option-modal.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, SharedModule, CommonModule],
})
export class trainingOptionModal {
  constructor(
    public router: Router,
    private cookieService: CookieService,
    private apiService: ApiservicesService,
    private dialogRef: MatDialogRef<trainingOptionModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
  }

  public intervalId: any;

  goToDashboard(val: any) {
    if (val == 'gameplanbook') {
      window.open(
        `${environment.nethen_calender_redirect_link}?name=${this.data.userinfo.name}&email=${this.data.userinfo.email}`,
        '_blank'
      );
      this.checkFlagAndRedirect();
      this.intervalId = setInterval(() => {
        this.checkFlagAndRedirect();
      }, 15000);
    } else {
      this.dialogRef.close();
    }
  }

  checkFlagAndRedirect() {
    console.log(this.data);

    let reqBody = {
      email: this.data?.userinfo.email,
    };
    this.apiService
      .getHttpDataPostWithoutBaseUrl(
        'https://i526y91jwg.execute-api.us-east-2.amazonaws.com/dev/api/check-atten-email-availability',
        reqBody
      )
      .subscribe({
        next: (response: any) => {
          console.log(response);
          if (response.status == 'success' && response.emailExists) {
            clearInterval(this.intervalId);
            this.dialogRef.close();
            this.router.navigateByUrl('/rep-dashboard');
          }
        },
        error: (error: any) => {},
      });
  }
}

// << --------------- All Training Success Modal Component ----------------- >>

@Component({
  selector: 'training-success-modal',
  templateUrl: 'training-success-modal.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, SharedModule, CommonModule],
})
export class trainingSuccessModal {
  constructor(
    public router: Router,
    private dialogRef: MatDialogRef<trainingSuccessModal>,
    private apiService: ApiservicesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('datadatadatadatadata', data);
  }
  public intervalId: any;
  goToCalender(val: any) {
   
    window.open(
      `${environment.nethen_calender_redirect_link}?name=${this.data.userinfo.name}&email=${this.data.userinfo.email}`,
      '_blank'
    );
    this.checkFlagAndRedirect();
    this.intervalId = setInterval(() => {
      this.checkFlagAndRedirect();
    }, 15000);
  }

  checkFlagAndRedirect() {
    console.log(this.data);

    let reqBody = {
      email: this.data?.userinfo.email,
    };
    this.apiService
      .getHttpDataPostWithoutBaseUrl(
        'https://i526y91jwg.execute-api.us-east-2.amazonaws.com/dev/api/check-atten-email-availability',
        reqBody
      )
      .subscribe({
        next: (response: any) => {
          console.log(response);
          if (response.status == 'success' && response.emailExists) {
            clearInterval(this.intervalId);
            this.dialogRef.close();
            this.router.navigateByUrl('/rep-dashboard');
          }
        },
        error: (error: any) => {},
      });
  }
}
