<app-header></app-header>
<div class="mainwrapper trainings_mainwrapper">
  <div class="new-container">
    <div class="subwrapper">
      <div class="sub-title wrapper">
        <h2>Trainings</h2>
      </div>

      <!-- <div class="sub-title wrapper traning_info">
        <h3>Please complete all video training.</h3>
        <p>
          Once you have watched all videos from start to finish, the back office
          will be unlocked, and you will be able to start entering leads.
        </p>
      </div> -->

      <div class="trainingbanner_mainwrap">
        <div class="trainingbanner_innerwrap">
          <h4>
            We highly suggest that you train on every opportunity, specially those 
you plan to selland the back office on how to use your back office.
          </h4>
          <button (click)="completeAllTrainings()">I'll go to dashboard</button>
        </div>
      </div>

      

      <!-- <div class="sub-title wrapper">
      </div> -->

      <ng-container *ngIf="hideContent"
        ><mat-progress-bar mode="indeterminate"></mat-progress-bar
      ></ng-container>

      <div class="lesson_prgrscont" *ngIf="!hideContent">
        <div class="prgrscont">
          <div class="prgrscont1">
            <label
              >You have completed
              <span id="done">{{
                trainingPercent.lesson_done - trainingPercent.lesson_not_done
              }}</span
              >/<span id="notdone">{{ trainingPercent.lesson_done }}</span>
              lessons</label
            >

            <div class="loderBodyCon">
              <div class="loderBody">
                <div
                  class="lodercon"
                  [style.width]="trainingPercent.percentage + '%'"
                ></div>
              </div>
              <span
                ><span>{{ trainingPercent.percentage }}%</span></span
              >
            </div>
          </div>
          <div class="prgrscont2">
            <div class="lessonactivity completed">
              <span></span>
              <label>Completed Lesson</label>
            </div>
            <div class="lessonactivity ongoing">
              <span></span>
              <label>Ongoing Lesson</label>
            </div>
            <div class="lessonactivity incomplete">
              <span></span>
              <label>Incomplete Lesson</label>
            </div>
          </div>
        </div>
      </div>
      
      <div class="wrapper" *ngIf="!hideContent">
        <!-- Required Trainings -->
        <h1 class="training_heading">Required Trainings :</h1>

        <h2 class="training_categoryname">
          Currently available opportunities :
        </h2>
        <ng-container *ngFor="let data of cliclspeedVideos; let i = index">
          <!-- <h2 class="training_categoryname">{{ data.category_name }}</h2> -->
          <ng-container
            *ngFor="let trainingrequired of data.category_data; let j = index"
          >
            <div
              class="training_datawrap"
              [id]="trainingrequired._id"
            >
              <div class="training_datawrapinner">
                <h1
                  [ngClass]="{
                    training_title: true,
                    onprogress: trainingrequired.status == 'onprogress',
                    is_completed: trainingrequired.status == 'is_completed',
                    not_done: trainingrequired.status == 'not_done'
                  }"
                  (click)="
                    videoToggle(trainingrequired._id, trainingrequired)
                  "
                >
                  <strong>{{ trainingrequired.title }}</strong
                  >
                  <span class="ng-star-inserted">
                    <i
                      aria-hidden="true"
                      [ngClass]="
                        activeIndex == trainingrequired._id
                          ? 'fa fa-chevron-circle-down'
                          : 'fa fa-chevron-circle-right'
                      "
                      class="fa"
                    ></i>
                  </span>
                </h1>
                <div
                  class="training_videowrap"
                  *ngIf="activeIndex == trainingrequired._id"
                >
                  <div class="training_video">
                    <video
                      controls
                      [poster]="trainingrequired.video_poster"
                      [autoplay]="trainingrequired.status == 'onprogress'"
                      (ended)="onVideoEnd(trainingrequired._id)"
                    >
                      <source
                        *ngIf="!loader"
                        [src]="trainingrequired.video_url"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <p class="training_desc">
                    {{ trainingrequired.description }}
                  </p>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <h2 class="training_categoryname">
          Available after second quarter of 2025 :
        </h2>
        <ng-container *ngFor="let data of othercliclspeedVideos; let i = index">
          <!-- <h2 class="training_categoryname">{{ data.category_name }}</h2> -->
          <ng-container
            *ngFor="let trainingrequired of data.category_data; let j = index"
          >
            <div
              class="training_datawrap"
              [id]="trainingrequired._id"
            >
              <div class="training_datawrapinner">
                <h1
                  [ngClass]="{
                    training_title: true,
                    onprogress: trainingrequired.status == 'onprogress',
                    is_completed: trainingrequired.status == 'is_completed',
                    not_done: trainingrequired.status == 'not_done'
                  }"
                  (click)="videoToggle(trainingrequired._id, trainingrequired)"
                >
                  <strong>{{ trainingrequired.title }}</strong>
                  <span class="ng-star-inserted">
                    <i
                      aria-hidden="true"
                      [ngClass]="
                        activeIndex == trainingrequired._id
                          ? 'fa fa-chevron-circle-down'
                          : 'fa fa-chevron-circle-right'
                      "
                      class="fa"
                    ></i>
                  </span>
                </h1>
                <div
                  class="training_videowrap"
                  *ngIf="activeIndex == trainingrequired._id"
                >
                  <div class="training_video">
                    <video
                      controls
                      [poster]="trainingrequired.video_poster"
                      [autoplay]="trainingrequired.status == 'onprogress'"
                      (ended)="onVideoEnd(trainingrequired._id)"
                    >
                      <source
                        *ngIf="!loader"
                        [src]="trainingrequired.video_url"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <p class="training_desc">
                    {{ trainingrequired.description }}
                  </p>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <!-- Optional Trainings -->
        <h1 class="training_heading">Optional Trainings :</h1>
        <ng-container *ngFor="let data of optionalVideos; let i = index">
          <!-- <h2 class="training_categoryname">{{ data.category_name }}</h2> -->
          <ng-container
            *ngFor="let trainingoptional of data.category_data; let j = index"
          >
            <div
              class="training_datawrap"
              [id]="trainingoptional._id"
            >
              <div class="training_datawrapinner">
                <h1
                  [ngClass]="{
                    training_title: true,
                    onprogress: trainingoptional.status == 'onprogress',
                    is_completed: trainingoptional.status == 'is_completed',
                    not_done: trainingoptional.status == 'not_done'
                  }"
                  (click)="videoToggle(trainingoptional._id, trainingoptional)"
                >
                  <strong>{{ trainingoptional.title }}</strong>
                  <span class="ng-star-inserted">
                    <i
                      aria-hidden="true"
                      [ngClass]="
                        activeIndex == trainingoptional._id
                          ? 'fa fa-chevron-circle-down'
                          : 'fa fa-chevron-circle-right'
                      "
                      class="fa"
                    ></i>
                  </span>
                </h1>
                <div
                  class="training_videowrap"
                  *ngIf="activeIndex == trainingoptional._id"
                >
                  <div class="training_video">
                    <video
                      controls
                      [poster]="trainingoptional.video_poster"
                      [autoplay]="trainingoptional.status == 'onprogress'"
                      (ended)="onVideoEnd(trainingoptional._id)"
                    >
                      <source
                        *ngIf="!loader"
                        [src]="trainingoptional.video_url"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <p class="training_desc">
                    {{ trainingoptional.description }}
                  </p>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="loader" class="training_loaderwrapper">
    <ng-container>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>
  </div>
</div>
