<div class="dialoghead preview_">
  <h1 class="mat-mdc-dialog-title preview_header">Media Source</h1>
  <mat-dialog-content>
<div class="customer_preview_modal">
  <!-- <button class="close_button" (click)="closeDialog()">X</button> -->
  <!-- <h2 class="customer_preview_modal_heading">Media</h2> -->
  <!-- <h1>Media Source</h1> -->
  <ul class="customer_preview_modal_data">
    <ng-container *ngIf="data.fileData.thumbnail?.type === 'image/png'">
      <li class="video_modal">
        <h2 class="customer_preview_modal_heading">Thumbnail</h2>
        <div class="thumble_image_wrppr">
          <img
            src="https://d37pbainmkhx6o.cloudfront.net/{{
              data.fileData?.thumbnail?.baseurl
            }}{{ data.fileData?.thumbnail?.fileservername }}"
            alt=""
          />
        </div>
      </li>
    </ng-container>

    <li class="video_modal" *ngIf="data.fileData.video">
      <h2 class="customer_preview_modal_heading">Video</h2>
      <div>
        <video width="700" height="450" controls>
          <source
            src="https://d37pbainmkhx6o.cloudfront.net/{{
              data.fileData?.video?.baseurl
            }}{{ data.fileData?.video?.fileservername }}"
            type="video/mp4"/>
        </video>
      </div>
    </li>

    <li *ngIf="data.fileData.youtube_video_link">
      <h2 class="customer_preview_modal_heading">Youtube</h2>
      <div>
        <iframe
          [src]="safeURL"
          frameborder="0"
          width="900"
          height="450"
          controls
        ></iframe>
      </div>
    </li>

    <!-- <ng-container *ngIf="data.files?.type.includes('application') ||('text/html')">
      <li *ngIf="data.files"> 
        <h2 class="customer_preview_modal_heading">Files</h2> 
        <div class="files_upload">         
          <iframe class="doc" src="https://docs.google.com/gview?url=http://writing.engr.psu.edu/workbooks/formal_report_template.doc&embedded=true"></iframe>                 
          <P>https://view.officeapps.live.com/op/embed.aspx?src=https://d37pbainmkhx6o.cloudfront.net/{{data?.files?.baseurl}}{{data?.files?.fileservername}}&embedded=true
          </P>
        </div> 
    </li>
    </ng-container> -->

    <ng-container
  *ngIf="data.fileData.files?.type.includes('application') || data.fileData.files?.type.includes('text/html') || data.fileData.files?.type.includes('pdf') || data.fileData.files?.type.includes('document')">
  <li class="files_modal" *ngIf="data.fileData.files">
    <span>
      <strong>Title:</strong> {{ data.fileData.title }}
      <i
        matTooltip="{{ data.fileData.description }}"
        matTooltipPosition="below"
        class="fa-solid fa-circle-info">
      </i>
    </span>

    <ng-container *ngIf="data.fileData.files?.type === 'application/pdf'">
      <div class="files_upload">
        <iframe class="doc" [src]="iframeUrl"> </iframe>
      </div>
    </ng-container>

    <ng-container *ngIf="data.fileData.files?.type !== 'application/pdf'">
      <div class="file_icon">
        <img style="max-width: 100%;" src="assets/images/file_icon2.png" alt="">
        <br />
        <a matTooltip="Download File"
        matTooltipPosition="below" [href]="fileUrl" [download]="data.fileData.files?.name" class="download-link">
          <i class="fa fa-download" aria-hidden="true"></i>
        </a>
      </div>
    </ng-container>
  </li>
</ng-container>

    <!-- <span class="close-btn-modal" (click)="closePreview()">x</span> -->
 
    <i class="close-btn-modal fa-solid fa-x" (click)="closePreview()" mat-raised-button
    matTooltip="Close"
    matTooltipPosition="below"></i>


  </ul>

  <!-- <button class="close_button" (click)="closeDialog()">X</button> -->
  <!-- <p>{{data.youtube_video_link}}</p> -->
</div>
</mat-dialog-content>
</div>
